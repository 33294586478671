import React, { useEffect, useMemo, useState } from 'react';
import styles from './mobileMenu.module.scss';
import useRoutes from '@/hooks/useRoutes';
import Logo from '@/components/Logo';
import CloseIcon from '#/images/icons/close_icon.svg';
import BurgerIcon from '#/images/icons/burger_icon.svg';
import { Button, IconButton } from '@/components/Buttons';
import { availableLanguages, DASHBOARD_URL, ROUTES } from '@/lib/constants.mjs';
import { getCookieByName, getRefLink } from '@/lib/helpers';
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { Route } from '@/types/generalTypes';
import { usePathname } from 'next/navigation';
import Link from 'next/link';
import ArrowDownIcon from '#/images/icons/arrow_down.svg';
import { Icon } from '@/components/Header/DesktopMenu/IconDropdown';
import { useTranslation } from 'next-i18next';

import { useRouter } from 'next/router';
import LangIcon from '#/images/icons/langs_icon.svg';

interface MobileMenuProps {
    dark?: boolean;
}

const MobileMenu: React.FC<MobileMenuProps> = ({ dark = false }) => {
    const [isLoggedInDashboard, setLoggedInDashboard] = useState(false);
    const [isOpen, setIsOpen] = React.useState(false);
    const routes = useRoutes();
    const router = useRouter();
    const path = usePathname();
    const { t } = useTranslation('common');
    const mobileRoutes: Route[] = useMemo(() => {
        return [
            {
                visibleName: 'home_menu_item_label',
                route: ROUTES.HOME,
                icon: null,
                subItems: [],
            },
            ...routes,
        ];
    }, [routes]);
    useEffect(() => {
        if (document) {
            if (process.env.NEXT_PUBLIC_ENVR === getCookieByName('loggedEnv'))
                setLoggedInDashboard(getCookieByName('isAuth') === 'true');
        }
    }, []);
    useEffect(() => {
        setIsOpen(false);
    }, [path]);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    const changeLanguage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const locale = event.target.value;
        document.cookie = `NEXT_LOCALE=${locale}; max-age=31536000; path=/`;
        router.replace(router.asPath, router.asPath, { locale: locale });
    };

    const defineLangKey = (lang: string) => {
        // @ts-ignore
        return availableLanguages[lang].long;
    };
    return (
        <>
            <div
                className={`mobile ${styles.mobileMenu} ${
                    isOpen ? styles.mobileMenuOpen : styles.mobileMenuHide
                }`}
            >
                <Logo svgClassName={isOpen ? styles.logoBlue : ''} />
                <div className={styles.burgerMenu}>
                    {isOpen ? (
                        <IconButton onClick={toggleMenu}>
                            <CloseIcon className={styles.icon} />
                        </IconButton>
                    ) : (
                        <div className={styles.languagesSelectContainer}>
                            <div className={styles.languagesSelectWrapper}>
                                <LangIcon />
                                <select
                                    defaultValue={router.locale}
                                    onChange={changeLanguage}
                                    className={styles.customSelect}
                                >
                                    {(Object.keys(availableLanguages) as string[]).map(
                                        (language) => (
                                            <option key={language} value={language}>
                                                {defineLangKey(language)}
                                            </option>
                                        ),
                                    )}
                                </select>
                            </div>
                            <IconButton onClick={toggleMenu}>
                                <BurgerIcon className={styles.icon} />
                            </IconButton>
                        </div>
                    )}
                </div>
            </div>
            <nav className={`${styles.nav} ${isOpen ? styles.show : ''}`}>
                <div className={styles.buttonContainer}>
                    {isLoggedInDashboard ? (
                        <Button
                            size={'full'}
                            color={'navy'}
                            to={`${DASHBOARD_URL}/dashboard`}
                            blank={false}
                        >
                            {t('return_to_dashboard_button_label' as any)}
                        </Button>
                    ) : (
                        <>
                            <Button
                                size={'full'}
                                color={'grey'}
                                asAnchor
                                to={`${DASHBOARD_URL}/login`}
                            >
                                {t('login_button_label' as any)}
                            </Button>
                            <Button size={'full'} color={'navy'} to={getRefLink()} blank={false}>
                                {t('register_button_label' as any)}
                            </Button>
                        </>
                    )}
                </div>
                <Accordion className={styles.list} allowZeroExpanded>
                    {mobileRoutes.map((route) => {
                        const props: any = {};
                        if (route.disabledPrefetch) {
                            props.prefetch = false;
                        }
                        return (
                            <AccordionItem
                                key={route.visibleName}
                                className={styles.listItemContainer}
                            >
                                {route.subItems.length > 0 ? (
                                    <AccordionItemHeading className={styles.heading}>
                                        <AccordionItemButton className={styles.listItem}>
                                            <p className={`as-h3`}>{t(route.visibleName as any)}</p>
                                            <ArrowDownIcon className={styles.arrowIcon} />
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                ) : (
                                    <Link
                                        {...props}
                                        href={route.route as string}
                                        className={`as-h3 ${styles.link} ${
                                            path === route.route ? styles.active : ''
                                        }`}
                                    >
                                        {t(route.visibleName as any)}
                                    </Link>
                                )}
                                {route.subItems.length > 0 && (
                                    <AccordionItemPanel className={styles.heading}>
                                        <Accordion
                                            className={styles.list}
                                            allowZeroExpanded
                                            style={{ gap: '20px' }}
                                        >
                                            {route.subItems.map((subItem) => (
                                                <AccordionItem
                                                    key={subItem.visibleName}
                                                    className={styles.subItemContainer}
                                                >
                                                    <Link
                                                        href={subItem.route as string}
                                                        className={`as-h4 ${styles.link}`}
                                                    >
                                                        {subItem.icon && (
                                                            <Icon
                                                                iconImage={subItem.icon}
                                                                className={styles.subIcon}
                                                            />
                                                        )}
                                                        {t(subItem.visibleName as any)}
                                                    </Link>
                                                </AccordionItem>
                                            ))}
                                        </Accordion>
                                    </AccordionItemPanel>
                                )}
                            </AccordionItem>
                        );
                    })}
                </Accordion>
            </nav>
        </>
    );
};

export default MobileMenu;
