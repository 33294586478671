import React from 'react';
import DesktopMenu from './DesktopMenu/DesktopMenu';
import MobileMenu from '@/components/Header/MobileMenu/MobileMenu';

interface HeaderProps {
    isTransparent?: boolean;
    className?: string;
    dark?: boolean;
}
const Header: React.FC<HeaderProps> = ({ isTransparent = true, className = '', dark = false }) => {
    return (
        <header className={`header ${isTransparent ? '' : 'fill-bg'} ${className}`}>
            <DesktopMenu dark={dark} />
            <MobileMenu dark={dark} />
        </header>
    );
};

export default Header;
