// eslint-disable-next-line @typescript-eslint/no-var-requires
const Backend = require('i18next-http-backend/cjs');
const getTranslationsHost = () => {
    let host = 'https://translations.coinmarketman.com';
    if (process.env.NODE_ENV === 'development') {
        host = 'http://localhost:3009';
    } else if (process.env.NEXT_PUBLIC_ENVR === 'dev') {
        host = 'https://dev-translations.coinmarketman.com';
    } else if (process.env.NEXT_PUBLIC_ENVR === 'stg') {
        host = 'https://stg-translations.coinmarketman.com';
    }
    return `${host}/translations/next-static/%{lng}/%{ns}.json`;
};

module.exports = {
    // https://www.i18next.com/overview/configuration-options#logging
    debug: false, //process.env.NODE_ENV === 'development',
    use: [Backend],
    backend: {
        loadPath: getTranslationsHost(),
    },
    serializeConfig: false,
    i18n: {
        defaultLocale: 'en',
        locales: ['en', 'ru', 'uk', 'ko', 'ja', 'zh'],
    },
    interpolation: {
        prefix: '%{',
        suffix: '}',
    },
    reloadOnPrerender: process.env.NODE_ENV === 'development',
};
