import React, { useEffect, useRef, useState } from 'react';
import { availableLanguages } from '@/lib/constants.mjs';
import styles from './languageSelect.module.scss';
import { useRouter } from 'next/router';

type Locales = 'en' | 'ja' | 'ko' | 'ru' | 'uk' | 'zh';
const LanguageSelect: React.FC = () => {
    const router = useRouter();
    const locale: Locales = (router.locale as Locales) || 'en';
    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState<string>(availableLanguages[locale].short);

    const selectRef = useRef<HTMLDivElement | null>(null);
    const changeLanguage = (locale: string) => {
        document.cookie = `NEXT_LOCALE=${locale}; max-age=31536000; path=/`;
        router.replace(router.asPath, router.asPath, { locale: locale });
    };
    const handleSelect = (option: string) => {
        setSelected(defineLangKey(option));
        changeLanguage(option);
        setIsOpen(false);
    };

    const toggleDropdown = () => {
        setIsOpen((prev) => !prev);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (selectRef.current && !selectRef.current?.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const defineLangKey = (lang: string) => {
        // @ts-ignore
        return availableLanguages[lang].short;
    };

    return (
        <div className={styles.customSelect} ref={selectRef}>
            <div className={styles.selected} onClick={toggleDropdown}>
                {selected}
            </div>
            {isOpen && (
                <div className={styles.options}>
                    {Object.keys(availableLanguages).map((option, index) => (
                        <div
                            key={index}
                            className={styles.option}
                            onClick={() => handleSelect(option)}
                        >
                            {defineLangKey(option)}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default LanguageSelect;
