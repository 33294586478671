import React from 'react';
import styles from './cookies.module.scss';
import Link from '@/components/Link';
import { ROUTES } from '@/lib/constants.mjs';
import { Button } from '@/components/Buttons';
import { useTranslation } from 'next-i18next';

const cookieBannerName = 'cmmCookieBanner';

//https://api.zopim.com/files/meshim/widget/controllers/liveChatAPI/CookieLawAPI-js.html

interface CookiesList {
    [key: string]: any;
}

const CookiesPopup = () => {
    const [showPopup, setShowPopup] = React.useState(false);
    const [activeCookiesList, setActiveCookiesList] = React.useState({});
    const { t } = useTranslation('cookies', { keyPrefix: 'cookies_popup' });

    React.useEffect(() => {
        const { localStorage } = window;
        const wasShown = localStorage.getItem(cookieBannerName);

        if (!wasShown || wasShown !== 'accepted') {
            setShowPopup(true);
        }

        const spl = document.cookie.split(';');
        const cookies: CookiesList = {};
        spl.forEach((cookie) => {
            const [key, value] = cookie.split('=');
            cookies[key] = value;
        });
        if (Object.keys(cookies).length > 0) {
            setActiveCookiesList(cookies);
        }

        setTimeout(() => {
            const spl = document.cookie.split(';');
            const cookies = {};
            spl.forEach((cookie) => {
                const [key, value] = cookie.split('=');
                // @ts-ignore
                cookies[key as string] = value;
            });
            if (Object.keys(cookies).length > 0) {
                setActiveCookiesList({ ...activeCookiesList, ...cookies });
            }
        }, 5000);
    }, []);
    const onAcceptClick = () => {
        if (typeof window !== 'undefined') {
            window.localStorage.setItem(cookieBannerName, 'accepted');
        }
        // enableZopim();
        setShowPopup(false);
    };

    const onRejectClick = () => {
        if (typeof window !== 'undefined') {
            window.localStorage.setItem(cookieBannerName, '');
            if (Object.keys(activeCookiesList).length > 0) {
                Object.keys(activeCookiesList).forEach((cookie) => {
                    document.cookie = `${cookie}=null; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
                });
                setActiveCookiesList({});
            }
        }
        setShowPopup(false);
    };

    if (!showPopup) {
        return null;
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.box}>
                <div className={styles.textWrapper}>
                    <p className={styles.text}>
                        {t('text' as any)}
                        <Link
                            to={ROUTES.COOKIES}
                            label={t('cookies_policy_label' as any) as string}
                            className={styles.link}
                        />
                    </p>
                </div>
                <div className={styles.buttonsWrapper}>
                    <Button
                        size={'medium'}
                        color={'primary'}
                        onClick={onAcceptClick}
                        asAnchor={false}
                    >
                        {t('accept_button_label' as any)}
                    </Button>
                    <Button
                        size={'medium'}
                        color={'light'}
                        onClick={onRejectClick}
                        asAnchor={false}
                        className={styles.buttonText}
                    >
                        {t('reject_button_label' as any)}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default React.memo(CookiesPopup);
