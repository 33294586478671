import React from 'react';
import styles from './desktopMenu.module.scss';
import PropTypes from 'prop-types';
import Link from '@/components/Link';
import { Route } from '@/types/generalTypes';
import { useTranslation } from 'next-i18next';

interface SimpleDropdownProps {
    subItems: Omit<Route, 'subItems'>[];
    dark?: boolean;
}

const SimpleDropdown: React.FC<SimpleDropdownProps> = ({ subItems = [], dark = false }) => {
    const { t } = useTranslation('common');
    return (
        <div className={styles.simpleWrapper}>
            <div className={`${styles.simpleHead} ${dark ? styles.simpleHeadDark : ''}`} />
            <div className={`${styles.simpleBody} ${dark ? styles.simpleBodyDark : ''}`}>
                {subItems.map((subItem) => (
                    <Link
                        key={`${subItem.visibleName}_${subItem.route}`}
                        color={dark ? 'light' : 'navy'}
                        to={subItem.route as string}
                        label={t(subItem.visibleName as any) as string}
                        className={styles.simpleItem}
                    />
                ))}
            </div>
        </div>
    );
};

SimpleDropdown.propTypes = {
    subItems: PropTypes.array.isRequired,
};
export default SimpleDropdown;
