import * as React from 'react';
import { memo } from 'react';
import styles from './desktopMenu.module.scss';
import SimpleDropdown from './SimpleDropdown';
import IconDropdown from './IconDropdown';
import { Route } from '@/types/generalTypes';
import Link from '@/components/Link';
import { useTranslation } from 'next-i18next';

interface DesktopMenuItemProps {
    routeDef: Route;
    dark?: boolean;
}

const DesktopMenuItem: React.FC<DesktopMenuItemProps> = ({ routeDef, dark = false }) => {
    const { visibleName, route, subItems } = routeDef;
    const { t } = useTranslation('common');
    const props: any = {
        color: 'light',
        to: route as string,
        label: t(routeDef.visibleName as any) as string,
        className: styles.mainLink,
    };

    if (routeDef.disabledPrefetch) {
        props.prefetch = false;
    }
    if (subItems?.length === 0) {
        return <Link {...props} />;
    }

    if (visibleName === 'company_menu_item_label') {
        return (
            <div className={styles.asLink}>
                <p>{t(visibleName as any)}</p>
                <SimpleDropdown subItems={subItems} dark={dark} />
            </div>
        );
    }

    if (visibleName === 'feature_menu_item_label') {
        return (
            <div className={styles.asLink}>
                <p>{t(visibleName as any)}</p>
                <IconDropdown subItems={subItems} dark={dark} />
            </div>
        );
    }

    return null;
};
export default memo(DesktopMenuItem);
