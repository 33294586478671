import React from 'react';
import queryString from 'query-string';
import { useRouter } from 'next/router';

const useRefLink = () => {
    const router = useRouter();
    React.useEffect(() => {
        if (typeof window !== 'undefined') {
            const localStorage = window.localStorage;
            const { search } = window.location;

            const { ref } = queryString.parse(search);

            const cmmRef = localStorage.getItem('cmmRefer');
            const oldRef = cmmRef ? JSON.parse(cmmRef)?.ref : null;

            if (ref && oldRef !== ref) {
                localStorage.setItem(
                    'cmmRefer',
                    JSON.stringify({ ref, clickDate: Math.floor(new Date().getTime() / 1000) }),
                );
            }
        }
    }, [router.asPath]);
};

export default useRefLink;
