import React from 'react';
import styles from './link.module.scss';
import { Color } from '@/types/generalTypes';
import NextLink from 'next/link';

export type RouteHref = string | { pathname: string; query: { [key: string]: string } };

interface LinkProps {
    to: RouteHref;
    label: string;
    icon?: boolean;
    color?: Color;
    className?: string;
    onClick?: () => void | Promise<void>;
    isExternal?: boolean;
    prefetch?: boolean;
    target?: string;
}

const Link: React.FC<LinkProps> = ({
    to,
    color = 'primary',
    label,
    onClick = () => {},
    icon = false,
    className = '',
    isExternal = false,
    prefetch = true,
    target = '',
}) => {
    const colors = ['primary', 'light', 'navy'];
    const defineColor = () => {
        return colors.includes(color) ? styles[color] : styles.primary;
    };

    const Icon = () => (icon ? <span className={styles.icon}>{'>'}</span> : null);

    if (isExternal) {
        return (
            <a
                className={`label ${styles.link} ${defineColor()} ${className}`}
                target={`${target || ''}`}
                href={typeof to === 'string' ? to : undefined}
                onClick={onClick}
                rel={'noopener noreferrer'}
            >
                <span className={styles.linkLabel}>{label}</span>
                <Icon />
            </a>
        );
    }

    const props: any = {
        className: `label ${styles.link} ${defineColor()} ${className}`,
        href: to,
    };

    if (!prefetch) {
        props.prefetch = false;
    }

    return (
        <NextLink {...props}>
            <span className={styles.linkLabel}>{label}</span>
            <Icon />
        </NextLink>
    );
};

export default Link;
