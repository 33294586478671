import React from 'react';
import styles from './desktopMenu.module.scss';
import { Route } from '@/types/generalTypes';
import { usePathname } from 'next/navigation';
import Link from '@/components/Link';
import { useTranslation } from 'next-i18next';

export const Icon: React.FC<{ iconImage: any; className: string }> = ({ iconImage, className }) => {
    const Component = iconImage;
    return <Component className={className} />;
};

interface IconDropdownProps {
    subItems: Omit<Route, 'subItems'>[];
    dark?: boolean;
}

const IconDropdown: React.FC<IconDropdownProps> = ({ subItems = [], dark = false }) => {
    const pathname = usePathname();
    const { t } = useTranslation('common');

    const defineActive = (routePath: string): boolean => {
        return `${routePath}/` === pathname || routePath === pathname;
    };

    return (
        <div className={styles.dropWrapper}>
            <div className={`${styles.dropHead} ${dark ? styles.dropHeadDark : ''} `} />
            <div className={`${styles.dropBody} ${dark ? styles.dropBodyDark : ''}`}>
                <div className={`${styles.dropBlockItemsWrapper} ${styles.dropPadding}`}>
                    {subItems.map((subItem) => (
                        <div
                            key={`${subItem.visibleName}_${subItem.route}`}
                            className={styles.dropItemWrapper}
                        >
                            {subItem.icon !== null && (
                                <Icon iconImage={subItem.icon} className={styles.dropIcon} />
                            )}
                            <div className={styles.dropTextWrapper}>
                                <Link
                                    color="navy"
                                    to={subItem.route as string}
                                    label={t(subItem.visibleName as any) as string}
                                    className={`${styles.dropItem} ${
                                        defineActive(subItem.route as string) ? styles.active : ''
                                    }`}
                                />
                                <p className={styles.dropSubTitle}>{t(subItem.subTitle as any)}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default IconDropdown;
