import { ROUTES } from '@/lib/constants.mjs';
import { BlogImageFormat, PostMeta } from '@/types/generalTypes';
import { NextPageContext } from 'next';
import { Duration } from 'date-fns';

export const getRefLink = () => {
    return ROUTES.REGISTRATION;
    // if (typeof window !== 'undefined' && window?.localStorage) {
    //     const localStorage = window.localStorage;
    //     const cmmRef = localStorage.getItem('cmmRefer');
    //
    //     if (!cmmRef) return ROUTES.REGISTRATION;
    //
    //     const { ref, clickDate } = JSON.parse(cmmRef);
    //
    //     if (!ref) return ROUTES.REGISTRATION;
    //
    //     return {
    //         pathname: ROUTES.REGISTRATION,
    //         query: { ref, click_date: clickDate },
    //     };
    // } else {
    //     return ROUTES.REGISTRATION;
    // }
};
export const snakifyShort = (str: string = '', limit: number = 4): string => {
    return str.split(' ').splice(0, limit).join('_').replace(/'/, 'g').toLowerCase();
};

export const getRefData = () => {
    const refLink = {
        ref: null,
        clickDate: null,
    };

    if (typeof window !== 'undefined' && window?.localStorage) {
        const localStorage = window.localStorage;
        const cmmRef = localStorage.getItem('cmmRefer');

        if (cmmRef) {
            try {
                const { ref, clickDate } = JSON.parse(cmmRef);

                refLink.ref = ref;
                refLink.clickDate = clickDate;
            } catch (e) {
                console.warn(e);
            }
        }
    }

    return refLink;
};

export const getRefLinkParams = () => {
    const params = {
        ref: '',
        click_date: '',
    };

    if (typeof window === 'undefined' || !window?.localStorage) return params;
    const cmmRef = localStorage.getItem('cmmRefer');
    if (!cmmRef) return params;
    const { ref, clickDate } = JSON.parse(cmmRef);
    if (!ref) return params;
    return {
        ref,
        click_date: clickDate,
    };
};

export const transformToPublicDomain = (url: string = ''): string => {
    const env =
        process.env.NEXT_PUBLIC_HOST &&
        !process.env.NEXT_PUBLIC_HOST.includes('dev') &&
        !process.env.NEXT_PUBLIC_HOST.includes('localhost') &&
        !process.env.NEXT_PUBLIC_HOST.includes('127.0.0.1')
            ? 'prod'
            : 'dev';
    const filename = url?.split('/').pop();
    return `https://${env === 'dev' ? 'dev-' : ''}cms-pub.coinmarketman.com/${filename}`;
};

export const defineImagePath = (
    post: PostMeta,
    options?: { width?: number; height?: number; format?: BlogImageFormat },
) => {
    if (typeof post.image === 'string') {
        return {
            src: `/images/blog-posts/${post.folder}/hero.jpg`,
            width: options?.width,
            height: options?.height,
        };
    }

    let url: string;
    if (options?.format && post?.image?.data?.attributes?.formats?.[options.format]) {
        url = transformToPublicDomain(
            post?.image?.data?.attributes?.formats?.[options.format]?.url,
        );
    } else {
        url = transformToPublicDomain(post?.image?.data?.attributes?.url);
    }

    return {
        src: url,
        width: post?.image?.data?.attributes?.width,
        height: post?.image?.data?.attributes?.height,
    };
};

export const formatNumber = (num: string | number, fraction: number = 2): string => {
    const value: number | bigint = +num < 0 ? +num * -1 : +num;
    return new Intl.NumberFormat('en-US', {
        maximumFractionDigits: fraction,
        minimumFractionDigits: fraction,
        //minimumSignificantDigits: 6,
    }).format(value);
};

export const partnersPagesRedirect = (context: NextPageContext): { props: any } => {
    // const { req, res } = context;
    // const route = (req?.url || '').split('/')[1];
    // if (!context.query.ref && res && req) {
    //     res.writeHead(301, {
    //         location: `?ref=${route}&click_date=${Math.floor(new Date().getTime() / 1000)}`,
    //     });
    //     res.end();
    // }
    return { props: {} };
};

export const nFormatter = (number: number): string | number => {
    const num = Math.abs(number);
    const sign = number >= 0 ? 1 : -1;
    if (num >= 1000000000) {
        return ((num * sign) / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
    }
    if (num >= 1000000) {
        return ((num * sign) / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (num >= 1000) {
        return ((num * sign) / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    }
    return num * sign;
};

export const nFormatterK = (number: any) => {
    const num = Math.abs(number);
    const sign = number >= 0 ? '' : '-';

    const formatB = 1000_000_000;
    const formatM = 1000_000;
    const formatK = 1000;

    const getFraction = (numericValue: number): number => {
        const toFormat = Math.abs(numericValue);
        const intPart = `${toFormat}`.split('.');
        const length = intPart[0].length;
        if (length >= 3) return 0;
        return Math.abs(length - 3);
    };

    if (num >= formatB) {
        const valueToFormat = num / formatB;
        return (
            sign + formatNumber(valueToFormat, getFraction(valueToFormat)).replace(/\.0$/, '') + 'B'
        );
    }
    if (num >= formatM) {
        const valueToFormat = num / formatM;
        return (
            sign + formatNumber(valueToFormat, getFraction(valueToFormat)).replace(/\.0$/, '') + 'M'
        );
    }
    if (num >= formatK) {
        const valueToFormat = num / formatK;
        return (
            sign + formatNumber(valueToFormat, getFraction(valueToFormat)).replace(/\.0$/, '') + 'K'
        );
    }

    return sign + formatNumber(num, getFraction(num)).replace(/\.0$/, '');
};

export const getSeoData = (postData: PostMeta) => {
    const seo = postData?.seo?.[postData?.seo?.length - 1];
    const generalSeo = {
        title: seo?.title,
        description: seo?.description,
        image: seo?.image?.data?.attributes,
    };
    let twitterSeo: any = {};
    let ogSeoData: any = {};
    seo?.metaSocial?.forEach((social: any) => {
        if (social?.socialNetwork?.toLowerCase() === 'twitter') {
            twitterSeo = { ...social, image: social?.image?.data?.attributes };
        } else {
            ogSeoData = { ...social, image: social?.image?.data?.attributes };
        }
    });
    if (generalSeo?.image?.url) {
        generalSeo.image.url = transformToPublicDomain(generalSeo.image.url);
    }
    if (twitterSeo?.image?.url) {
        twitterSeo.image.url = transformToPublicDomain(twitterSeo.image.url);
    }

    if (ogSeoData?.image?.url) {
        ogSeoData.image.url = transformToPublicDomain(ogSeoData.image.url);
    }
    return {
        generalSeo,
        twitterSeo,
        ogSeoData,
    };
};
export const getCookieByName = (name: string): string | undefined => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts?.pop()?.split(';')?.shift();
};

export const extractError = (error: any) => {
    const message = {
        message: '', // error message
        statusCode: null,
        error: '', // title
        reason: '',
        errorCode: '',
    };
    const internalResponse = error?.response?.data?.response;
    message.message = error?.response?.data?.message || 'Something went wrong';
    message.statusCode = error?.response?.data?.statusCode || null;
    message.error = error?.response?.data?.error || 'Error';
    message.reason = error?.response?.data?.reason || '';
    message.errorCode = error?.response?.data?.statusCode || '';

    if (Array.isArray(message.message)) {
        message.message = message.message.join(', ');
    }

    if (internalResponse?.error) {
        message.message = internalResponse?.error || 'Something went wrong';
        message.error = internalResponse?.message || 'Error';
    }

    return message;
};

export const secondsToDuration = (
    seconds: number,
    units = ['years', 'months', 'days', 'hours', 'minutes', 'seconds'],
): Duration => {
    const duration: Duration = {};
    let parsedSeconds = Math.floor(seconds);
    if (units?.includes('years')) {
        duration.years = Math.floor(parsedSeconds / (365 * 24 * 60 * 60));
        parsedSeconds %= 365 * 24 * 60 * 60;
    }

    if (units?.includes('months')) {
        duration.months = Math.floor(parsedSeconds / (30 * 24 * 60 * 60));
        parsedSeconds %= 30 * 24 * 60 * 60;
    }

    if (units?.includes('days')) {
        duration.days = Math.floor(parsedSeconds / (24 * 60 * 60));
        parsedSeconds %= 24 * 60 * 60;
    }

    if (units?.includes('hours')) {
        duration.hours = Math.floor(parsedSeconds / (60 * 60));
        parsedSeconds %= 60 * 60;
    }

    if (units?.includes('minutes')) {
        duration.minutes = Math.floor(parsedSeconds / 60);
        parsedSeconds %= 60;
    }

    if (units?.includes('seconds')) {
        duration.seconds = parsedSeconds;
    }
    return duration;
};

export const differenceInSeconds = (
    dateString1: string | Date,
    dateString2: string | Date,
): number => {
    try {
        const date1 = new Date(dateString1);
        const date2 = new Date(dateString2);

        const difference: number = date2.getTime() - date1.getTime();

        const differenceInMillis = Math.abs(difference);

        return Math.floor(differenceInMillis / 1000);
    } catch (error) {
        console.error(error);
        return 0;
    }
};
