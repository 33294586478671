import React, { useEffect, useState } from 'react';
import Logo from '@/components/Logo';
import styles from './desktopMenu.module.scss';
import useRoutes from '@/hooks/useRoutes';
import DesktopMenuItem from './DesktopMenuItem';
import { Button } from '@/components/Buttons';
import { getCookieByName, getRefLink } from '@/lib/helpers';
import { DASHBOARD_URL } from '@/lib/constants.mjs';
import { useTranslation } from 'next-i18next';
import LanguageSelect from '@/components/LanguageSelect';

interface DesktopMenuProps {
    dark?: boolean;
}

const DesktopMenu: React.FC<DesktopMenuProps> = ({ dark = false }) => {
    const { t } = useTranslation('common');
    const [isLoggedInDashboard, setLoggedInDashboard] = useState(false);
    const routes = useRoutes();
    useEffect(() => {
        if (document) {
            if (process.env.NEXT_PUBLIC_ENVR === getCookieByName('loggedEnv'))
                setLoggedInDashboard(getCookieByName('isAuth') === 'true');
        }
    }, []);

    return (
        <div className={`desktop ${styles.menu}`}>
            <nav className={styles.menuContent}>
                <div className={styles.logoContainer}>
                    <Logo />
                </div>
                <ul className={styles.listWrapper}>
                    {routes.map((route) => {
                        return (
                            <li key={route.visibleName}>
                                <DesktopMenuItem routeDef={route} dark={dark} />
                            </li>
                        );
                    })}
                </ul>
            </nav>
            <div className={styles.buttons}>
                <LanguageSelect />
                {isLoggedInDashboard ? (
                    <Button
                        color={dark ? 'primary' : 'navy'}
                        size="medium"
                        blank={false}
                        asAnchor
                        to={`${DASHBOARD_URL}/dashboard`}
                    >
                        {t('return_to_dashboard_button_label' as any)}
                    </Button>
                ) : (
                    <>
                        <Button
                            color="light"
                            size="medium"
                            blank
                            asAnchor
                            to={`${DASHBOARD_URL}/login`}
                        >
                            {t('login_button_label' as any)}
                        </Button>
                        <Button
                            color={dark ? 'primary' : 'navy'}
                            size="medium"
                            blank={false}
                            asAnchor
                            to={getRefLink()}
                        >
                            {t('register_button_label' as any)}
                        </Button>
                    </>
                )}
            </div>
        </div>
    );
};

export default DesktopMenu;
