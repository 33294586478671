import React, { useEffect } from 'react';
import Script from 'next/script';
import Smartlook from 'smartlook-client';

const ExternalScripts = () => {
    const analyticsTag = process.env.NEXT_PUBLIC_GA || 'G-XC11CV73RD';
    const zopimKey = '4tkZ0MDJSiHpTNj96D7LECpXkuprmxtc';

    useEffect(() => {
        if (!Smartlook.initialized() && process.env.NEXT_PUBLIC_ENVR === 'prod') {
            Smartlook.init('1bea1454d8934fe957ba5dfb0131dabd03a2f058');
        }
    }, []);

    return (
        <>
            <Script nonce={'5K6Ph31KoF'} id={'zopim'} strategy={'lazyOnload'}>
                {`window.$zopim||(function(d,s){var z=$zopim=function(c){
                    z._.push(c)},$=z.s=
                    d.createElement(s),e=d.getElementsByTagName(s)[0];z.set=function(o){z.set.
                    _.push(o)};z._=[];z.set._=[];$.defer=!0;$.setAttribute('charset','utf-8');
                    $.src='https://v2.zopim.com/?${zopimKey}';z.t=+new Date;$.
                    type='text/javascript';$.nonce='glBiZNFsDP';e.parentNode.insertBefore($,e)})(document,'script');
                    
                     $zopim(function() {
          
                  // $zopim.livechat.cookieLaw.comply();
                    // $zopim.livechat.cookieLaw.setDefaultImplicitConsent();
                    // $zopim.livechat.hideAll();
                 });
                    
                    
                    `}
            </Script>
            <Script
                strategy="lazyOnload"
                src={'https://www.googletagmanager.com/gtag/js?id=UA-135190839-1'}
                id={'gtag'}
                nonce={'sAOFi9ss1J'}
            />
            <Script nonce={'zhEiuBUr5d'} strategy="lazyOnload" id={'also-gtag'}>
                {`
                   window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'UA-135190839-1');`}
            </Script>
            {/*<Script strategy="lazyOnload" id={'smartlook'}>*/}
            {/*    {`*/}
            {/*                  window.smartlook||(function(d) {*/}
            {/*  var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];*/}
            {/*  var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';*/}
            {/*  c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c);*/}
            {/*  })(document);*/}
            {/*  smartlook('init', '1bea1454d8934fe957ba5dfb0131dabd03a2f058', { region: 'eu' });*/}
            {/*            `}*/}
            {/*</Script>*/}
            <Script
                src={`https://www.googletagmanager.com/gtag/js?id=${analyticsTag}`}
                strategy={'lazyOnload'}
                id={'new-ga'}
                nonce={'s5W8OEbvjPSd'}
            />
            <Script nonce={'TFjOVkmYA8yI'} strategy="lazyOnload" id={'new-also-gtag'}>
                {`
                     window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', '${analyticsTag}');
                `}
            </Script>
        </>
    );
};

export default ExternalScripts;
