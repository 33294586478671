import React from 'react';
import styles from './logo.module.scss';
import LogoIcon from '#/images/cmm-logo.svg';
import { ROUTES } from '@/lib/constants.mjs';
import { useRouter } from 'next/router';

interface LogoProps {
    onClick?: () => Promise<void> | void;
    className?: string;
    svgClassName?: string;
}

const Logo: React.FC<LogoProps> = ({ onClick, svgClassName, className }) => {
    const router = useRouter();
    const onLogoClick = () => {
        if (onClick) {
            onClick();
            return;
        }
        router.push(ROUTES.HOME);
    };

    return (
        <div className={`${styles.logo} ${className}`} onClick={onLogoClick}>
            <LogoIcon className={`${svgClassName}`} />
        </div>
    );
};

export default Logo;
