import React from 'react';
import styles from './notfound.module.scss';
import Section from '@/components/Section';
import Header from '@/components/Header';

import NotFoundIcon from '#/images/404/not_found_dark.svg';
import { Button } from '@/components/Buttons';
import { ROUTES } from '@/lib/constants.mjs';
import { useTranslation } from 'next-i18next';

const NotFound: React.FC<{ isError?: boolean; isCrash?: boolean }> = ({
    isError = false,
    isCrash = false,
}) => {
    const { t } = useTranslation('404');
    const onReloadClick = () => {
        if (typeof window !== 'undefined') {
            window.location.reload();
        }
    };

    return (
        <>
            <Header isTransparent={false} className={styles.header} />
            <Section>
                <div className={styles.content}>
                    <NotFoundIcon className={styles.icon} />
                    <p className="as-h2">{t('page_title' as any)}</p>
                    <p className="body">
                        {!isError ? t('subtitle_1' as any) : t('subtitle_2' as any)}
                    </p>
                    {isCrash ? (
                        <Button
                            color="primary"
                            asAnchor={false}
                            size="large"
                            className={styles.button}
                            onClick={onReloadClick}
                        >
                            {t('reload_button_label' as any)}
                        </Button>
                    ) : (
                        <Button
                            color="primary"
                            to={ROUTES.HOME}
                            size="large"
                            className={styles.button}
                        >
                            {t('back_to_home_button_label' as any)}
                        </Button>
                    )}
                </div>
            </Section>
        </>
    );
};

export default NotFound;
