import '@/styles/global.scss';
import type { AppProps } from 'next/app';
import { manrope } from '@/lib/font';
import Footer from '@/components/Footer';
import CookiesPopup from '@/components/CookiesPopup';
import useRefLink from '@/hooks/useRefLink';
import ExternalScripts from '@/components/ExternalScripts';
import Head from 'next/head';
import { HOST } from '@/lib/constants.mjs';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/nextjs';
import NotFound from '@/pageComponents/NotFound';
import { appWithTranslation } from 'next-i18next';
import nextI18NextConfig from '@/../next-i18next.config';

const App = ({ Component, pageProps }: AppProps) => {
    useRefLink();
    // useWebsocket();

    return (
        <>
            <Head>
                <meta name="viewport" content="initial-scale=1, width=device-width" />
                <meta charSet="utf-8" />
                <link rel="manifest" href={`${HOST}/manifest.json`} />
                <link rel="icon" type="image/svg+xml" href={`${HOST}/favicon.svg`} />
                <link rel="alternate icon" href={`${HOST}/favicon.ico`} />
                <link rel="mask-icon" href={`${HOST}/favicon.svg`} color="#247FFF" />
                <meta property="og:type" content={'website'} />
                <meta property="og:site_name" content="CoinMarketMan.com" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:creator" content="@CoinMarketMan" />
            </Head>
            <div className={manrope.className}>
                <style jsx global>{`
                    :root {
                        --manrope: ${manrope.style.fontFamily};
                    }
                `}</style>
                <SentryErrorBoundary fallback={<NotFound isError isCrash />}>
                    <Component {...pageProps} />
                    <Footer />
                    <CookiesPopup />
                </SentryErrorBoundary>
                <div className="captcha" id="captcha" />
            </div>
            <ExternalScripts />
        </>
    );
};

export default appWithTranslation(App, nextI18NextConfig);
