import React, { MouseEventHandler, PropsWithChildren } from 'react';
import { Color, Size } from '@/types/generalTypes';
import styles from './button.module.scss';
import NextLink from 'next/link';

interface ButtonProps {
    onClick?: MouseEventHandler<HTMLElement>;
    disabled?: boolean;
    size?: Size;
    mobileSize?: Size;
    color?: Color | 'grey';
    className?: string;
    asAnchor?: boolean;
    to?: string;
    blank?: boolean;

    [key: string]: any;
}

const Button: React.FC<PropsWithChildren<ButtonProps>> = ({
    children,
    onClick,
    disabled,
    size = 'medium',
    color = 'primary',
    className = '',
    asAnchor = true,
    to = '/',
    blank = false,
    mobileSize = undefined,
    ...rest
}) => {
    const defineSize = () => {
        if (!mobileSize) {
            return styles[size];
        }

        return `${styles[size]} ${styles[`mobile${mobileSize}`]}`;
    };

    if (asAnchor) {
        return (
            // eslint-disable-next-line react/jsx-no-target-blank
            <NextLink
                className={`label ${styles.button} ${defineSize()} ${styles[color]} ${className}`}
                target={blank ? '_blank' : ''}
                rel={blank ? 'noreferrer noopener' : ''}
                href={to}
                prefetch={false}
            >
                {children}
            </NextLink>
        );
    }
    return (
        <button
            className={`label ${styles.button} ${defineSize()} ${styles[color]} ${className}`}
            disabled={disabled}
            onClick={onClick}
            {...rest}
        >
            {children}
        </button>
    );
};

export default Button;
