import React, { PropsWithChildren } from 'react';
import styles from './iconButton.module.scss';

interface IconButtonProps {
    className?: string;
    onClick?: () => void | Promise<void>;
    disabled?: boolean;
}
const IconButton: React.FC<PropsWithChildren<IconButtonProps>> = ({
    className = '',
    onClick = () => {},
    children,
    disabled = false,
}) => {
    return (
        <button
            className={`${styles.buttonWrapper} ${className}`}
            onClick={onClick}
            disabled={disabled}
        >
            {children}
        </button>
    );
};
export default IconButton;
